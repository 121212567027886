import { Message, Loading } from './showToast'
import axios from '@/model/axios'
import loadsh from 'lodash'

/**
 * 封装axios
 * @type {{download: ajax.download, blob: ajax.blob, post: ajax.post, upload: ajax.upload, get: ajax.get}}
 */
export const ajax = {
  /**
   *
   * @param path 地址
   * @param data 传参
   * @param callback 回调
   * @param is_load 是否有加载
   */
  post: (path, data, callback = () => {}, is_load = true) => {
    if (is_load) Loading.showLoad()
    axios
      .post(path, data)
      .then((res) => {
        if (is_load) Loading.hideLoad()
        callback(res.data)
      })
      .catch((err) => {
        if (is_load) Loading.hideLoad()
        console.log(err)
        Message.error('请求错误')
      })
  },
  /**
   *
   * @param path 地址
   * @param data 参数
   * @param callback 回调
   * @param is_load 是否有加载
   */
  get: (path, callback = () => {}, is_load = true) => {
    if (is_load) Loading.showLoad()
    axios
      .get(path)
      .then((res) => {
        if (is_load) Loading.hideLoad()
        callback(res.data)
      })
      .catch((err) => {
        if (is_load) Loading.hideLoad()
        console.log(err)
      })
  },
  getPara: (path, page, callback = () => {}, is_load = true) => {
    let pageString = '?'
    loadsh.each(page, (item, index) => {
      pageString += index + '=' + item + '&'
    })
    pageString =pageString.substr(0,pageString.length-1)
    if (is_load) Loading.showLoad()
    axios
      .get(path + pageString)
      .then((res) => {
        if (is_load) Loading.hideLoad()
        callback(res.data)
      })
      .catch((err) => {
        if (is_load) Loading.hideLoad()
        console.log(err)
      })
  },
  /**
   * 文件上传
   * @param {*} actionUrl
   * @param {*} formData
   * @param {*} callback
   * @param {*} is_load
   */
  upload: (actionUrl, formData, callback = () => {}, is_load = true) => {
    if (is_load) Loading.showLoad()
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    axios.post(actionUrl, formData, config).then(
      (res) => {
        if (is_load) Loading.hideLoad()
        callback(res.data)
      },
      (err) => {
        if (is_load) Loading.hideLoad()
        console.log(err)
      }
    )
  },
  /**
   * 下载文件
   * @param path
   * @param data
   * @param callback
   * @param is_load
   */
  download: (path, data, callback = () => {}, is_load = true) => {
    if (is_load) Loading.showLoad()
    axios
      .post(path, data, {
        responseType: 'blob'
      })
      .then((res) => {
        if (is_load) Loading.hideLoad()
        callback(res.data)
      })
  },
  /**
   * blob到本地 配合下载文件使用
   * @param blobs 文件blob
   * @param name 下载后的名字
   */
  blob: (blobs, name) => {
    const blobUrl = URL.createObjectURL(blobs)
    const a = document.createElement('a')
    a.href = blobUrl
    a.download = name
    a.style.display = 'none'
    a.click()
    URL.revokeObjectURL(blobUrl)
  }
  //示例 blob下载
  // ajax.download(path,"",  res => {
  //     ajax.blob(res,item.f_name+"."+item.extension)
  // })
}
