import storage from '@/model/storage'
import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
//默认进入页面
const _default = "/index"
const routes = [{
  path: '/',
  redirect: _default,
},
{
  path: '/home',
  component: () => import('../views/HomeView.vue'),
  meta: {
    requireAuth: true
  },
  redirect: _default,
  children: [

    {
      path: '/index',
      component: () => import('@/views/index/IndexView.vue')
    },
    {
      path: '/merchant_user',
      component: () => import('@/views/user/UserList.vue')
    },
    {
      path: '/roles',
      component: () => import('@/views/user/RolesList.vue')
    },
    {
      path: '/order',
      component: () => import('@/views/order/OrderList.vue')
    },
    //催收列表
    {
      path: '/urge/collection',
      component: () => import('@/views/urge/collectionList.vue')
    },
    //逾期列表
    {
      path: '/urge/overdue',
      component: () => import('@/views/urge/overdueList.vue')
    },
    //黑名单列表
    {
      path: '/urge/blacklist',
      component: () => import('@/views/urge/blackList.vue')
    },
    //店铺详情
    {
      path: '/shop/information',
      component: () => import('@/views/shop/InformationView.vue')
    },
    //店铺详情
    {
      path: '/shop/address',
      component: () => import('@/views/shop/AddressView.vue')
    },
    //交易记录
    {
      path: '/finance/transaction',
      component: () => import('@/views/finance/TransactionView')
    },
    //转账记录
    {
      path: '/finance/transferRecords',
      component: () => import('@/views/finance/TransactionRecord')
    },
    //商户退款记录
    {
      path: '/finance/refundRecord',
      component: () => import('@/views/finance/RefundRecord')
    },
    //项目利润表
    {
      path: '/finance/profitStatement',
      component: () => import('@/views/finance/ProfitStatement')
    },
    //项目利润表
    {
      path: '/finance/orderFlow',
      component: () => import('@/views/finance/OrderFlow')
    },
    //逾期
    {
      path: '/finance/overdue',
      component: () => import('@/views/finance/OverdueWord')
    },
    //卡片仓库
    {
      path: '/shop/card',
      component: () => import('@/views/card/CardWarehouse')
    },
    
  ]
},
{
  path: '/login',
  component: () => import('../views/LoginView.vue')
},
{
  // 匹配为定义路由然后重定向到404页面
  path: '/:pathMath(.*)',
  redirect: '/404'
},
{
  // 定义404路由
  path: '/404',
  component: () => import('../views/NotfoundView.vue')
}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((r) => r.meta.requireAuth)) {
    if (storage.get('token')) {
      next() //有token,进行request请求，后台还会验证token
    } else {
      next({
        path: '/login'
      })
    }
  } else {
    next() //如果无需token,那么随它去吧
  }
})

export default router